<template>
  <div class="flex-container non scrollable">
    <page-title value="Gestion des utilisateurs"/>
    <div class="scrollable">
      <DataTable ref="dt" :value="users" :paginator="true" class="p-datatable-users" :rows="constants.defaultRowNumber" @rowSelect="clickUser" selection-mode="single"
                 dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
                 :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.users')})"
                 scrollable scroll-height="flex">
        <template #header>
          <div class="p-d-flex flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
            <Button type="button" label="Nouvel utilisateur" icon="pi pi-plus" @click="clickNewUser" />
          </div>
        </template>
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
        <Column field="last_name" :header="$t('lastname').toUpperCase()" sortable filterField="last_name" sortField="last_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('last_name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="first_name" :header="$t('firstname').toUpperCase()" sortable filterField="first_name" sortField="first_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('first_name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="email" :header="$t('email').toUpperCase()" sortable filterField="email" sortField="email" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">Email</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('email')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="last_access" :header="$t('last_access').toUpperCase()" sortable filterField="last_access" sortField="last_access" :showFilterMatchModes="false">
          <template #body="{data}">
            {{ data.last_access ? (new Date(data.last_access)).toLocaleDateString(undefined, { hour: "2-digit", minute: "2-digit" }) : 'Jamais connecté' }}
          </template>
        </Column>
        <Column field="custom_roles" :header="$t('role').toUpperCase()" sortable filterField="custom_roles" sortField="custom_roles" :showFilterMatchModes="false">
          <template #body="{data}">
            {{ data.custom_roles.map(r => r.label).join(', ') }}
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog v-if="currentUser" :draggable="false" v-model:visible="dialogUser" :style="{width: '40%'}" :header="currentUser.id ? 'Édition' : 'Création'" :modal="true" class="p-fluid">
      <div class="flex flex-column" style="gap: 1.5rem;">
        <div class="flex flex-column" style="gap: 1.5rem;">
          <div class="field">
            <label>Nom</label>
            <div>
              <InputText v-model="v$.last_name.$model" type="text"/>
              <div v-if="v$.last_name.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="name-error" v-for="(error, index) of v$.last_name.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Prénom</label>
            <div>
              <InputText v-model="v$.first_name.$model" type="text"/>
              <div v-if="v$.first_name.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="name-error" v-for="(error, index) of v$.first_name.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Email</label>
            <div>
              <InputText v-model="v$.email.$model" type="text"/>
              <div v-if="v$.email.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="name-error" v-for="(error, index) of v$.email.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Numéro de téléphone</label>
            <div>
              <InputMask v-if="!foreign" id="siret" v-model="v$.phone.$model" type="text" mask="99-99-99-99-99"
                         :class="{'p-invalid':v$.phone.$invalid && submitted}" class="w-full mb-2" unmask/>
              <div v-if="v$.phone.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="name-error" v-for="(error, index) of v$.phone.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Rôles</label>
            <div>
              <MultiSelect v-model="v$.userRoles.$model" :options="roles" optionLabel="label" data-key="id"></MultiSelect>
              <div v-if="v$.userRoles.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="name-error" v-for="(error, index) of v$.userRoles.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Signataire</label>
            <div>
              <Checkbox v-model="v$.signatory.$model" :binary="true"></Checkbox>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button v-if="currentUser.id" :label="(currentUser.status === 'active' ? 'Désactiver' : 'Réactiver')" :icon="currentUser.status === 'suspended' ? 'pi pi-play' : 'pi pi-pause'" class="p-button-text" @click="toggleStatusUser" />
        <Button v-if="currentUser.id" label="Supprimer" icon="pi pi-trash" class="p-button-text" @click="deleteUser" />
        <Button :label="(currentUser.id ? 'Enregistrer' : 'Créer')" icon="pi pi-check" class="p-button-text" @click="saveUser" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {FilterMatchMode} from "primevue/api";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "Users",
  components: {PageTitle},
  data () {
    return {
      submitted: false,
      dialogUser: false,
      currentUser: null,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      userRoles: [],
      signatory: false,
      filters: {
        'global': {value: null, matchMode: null},
        'last_name': {value: null, matchMode: FilterMatchMode.IN},
        'first_name': {value: null, matchMode: FilterMatchMode.IN},
        'email': {value: null, matchMode: FilterMatchMode.IN},
        'custom_roles': {value: null, matchMode: FilterMatchMode.IN},
      },
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      signatory: {},
      first_name: {
        required: helpers.withMessage('Prénom requis', required),
      },
      userRoles: {
        required: helpers.withMessage('Au moins un rôle requis', required),
      },
      last_name: {
        required: helpers.withMessage('Nom requis', required),
      },
      phone: {
        required: helpers.withMessage('Numéro de téléphone requis', required),
      },
      email: {
        required: helpers.withMessage('Email requis', required),
        email: helpers.withMessage('Email non valide', email)
      },
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    ...mapGetters({
      users: 'directusUsers/GET_USERS',
      roles: 'directusUsers/GET_ROLES',
    }),
  },
  async created () {
    await this.loadData()
  },
  methods: {
    async toggleStatusUser () {
      this.$store.state.misc.loading = true
      const data = {
        id: this.currentUser.id
      }
      if (this.currentUser.status === 'active') {
        data.status = 'suspended'
      } else {
        data.status = 'active'
      }
      await this.$store.dispatch('directusUsers/updateUser', data)

      this.$toast.add({severity:'success', summary:'Succès', detail:`Utilisateur ${data.status === 'active' ? 'activé' : 'désactivé'}`, life: 3000});
      await this.loadData()
      this.dialogUser = false
      this.currentUser = null
      this.$store.state.misc.loading = false
    },
    async deleteUser () {
      this.$confirm.require({
        header: 'Supprimer utilisateur',
        message: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ? Il ne pourra plus se connecter.',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          const data = {
            id: this.currentUser.id
          }
          await this.$store.dispatch('directusUsers/deleteUser', data)

          this.$toast.add({severity:'success', summary:'Succès', detail:`Utilisateur supprimé`, life: 3000});
          await this.loadData()
          this.dialogUser = false
          this.currentUser = null
          this.$store.state.misc.loading = false
          this.$confirm.close()
        },
        reject: () => {

        }
      })
    },
    async saveUser () {
      if (this.v$.$invalid) {
        this.submitted = true
        return
      }

      this.$store.state.misc.loading = true

      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        custom_roles: this.userRoles,
        id: this.currentUser.id,
        signatory: this.signatory
      }

      if (this.currentUser.id) await this.$store.dispatch('directusUsers/updateUser', data)
      else await this.$store.dispatch('directusUsers/createUser', data)

      this.$toast.add({severity:'success', summary:'Succès', detail:'Utilisateur enregistré', life: 3000});
      await this.loadData()
      this.dialogUser = false
      this.currentUser = null
      this.$store.state.misc.loading = false
    },
    clickUser (user) {
      this.currentUser = user.data
      this.first_name = this.currentUser.first_name
      this.last_name = this.currentUser.last_name
      this.email = this.currentUser.email
      this.phone = this.currentUser.phone
      this.userRoles = this.currentUser.custom_roles
      this.signatory = this.currentUser.signatory
      this.dialogUser = true
    },
    clickNewUser () {
      this.currentUser = {}
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.phone = ''
      this.userRoles = []
      this.dialogUser = true
    },
    commonFilter (field) {
      let array = this.users.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    async loadData () {
      this.$store.state.misc.loading = true
      await this.$store.dispatch('directusUsers/getAllUsers')
      await this.$store.dispatch('directusUsers/getAllRoles')
      this.$store.state.misc.loading = false
    },
  }
}
</script>

<style scoped>

</style>
